<template>
  <div class="field-row">
    <label :for="id">{{ text }}</label>
    <input
      type="text"
      value=""
      v-model="textComp"
      :placeholder="placeholder"
      :id="id"
    />
  </div>
</template>

<script>
export default {
  name: "FeedbackInput",
  props: {
    id: {
      type: Number,
      default: () => 0
    },
    text: {
      type: String,
      default: () => ""
    },
    placeholder: {
      type: String,
      default: () => ""
    }
  },
  computed: {
    textComp: {
      get() {
        return this.text;
      },
      set(value) {
        this.$emit("updateValue", { id: this.id, value });
      }
    }
  }
};
</script>

<style scoped></style>
